


























import { Component, Prop, Vue } from 'vue-property-decorator'

import { EducationLargeTaskPracticeQuestionResource } from '@/store/types'

@Component
export default class TaskPracticeQuestion extends Vue {
  @Prop({ required: true })
  private question!: EducationLargeTaskPracticeQuestionResource

  @Prop({ required: true })
  private index!: number

  private opened = false
}
